<template>
    <div class="container pt-5">
        <div class="row">
            <div class="col-12 text-center pb-3">
                <img class="img-fluid" width="300" src="@/assets/images/logo-ifth.png" alt="IFTH">
            </div>
        </div>
        <div class="row align-items-center justify-content-center">
            <div class="col-xl-7 col-lg-8 col-md-10 col-10">
                <div class="card card--ifth p-3">
                    <div class="row">
                        <div class="col pt-5 pb-5">
                            <div v-if="loading" class="text-center">
                                <Loading />
                            </div>
                            <div v-else>
                                <form @submit.prevent="register">
                                    <h2 class="text-center">
                                        Plateforme mevaho : méthode de validation humaine On-line
                                    </h2>
                                    <div class="text-center mb-4"><em>par IFTH</em></div>
                                    <div>
                                        <p>
                                            Saisissez votre adresse mail pour commencer : 
                                        </p>
                                        <p>
                                            <input type="text" v-model="email" class="form-control" placeholder="jean@dupont.fr" required>
                                        </p>
                                        <div class="alert alert-warning" v-if="errors && errors.length > 0">
                                            {{ errors }}
                                        </div>
                                    </div>
                                    <div style="font-size:14px;color:#565656">
                                        <p>
                                            Les données à caractère personnel ou non enregistrées à l’occasion de cette étude feront l’objet d’un traitement informatisé automatisé par le promoteur pour son compte ou le compte du projet de recherche concerné.
                                        </p>
                                        <p>
                                            Les données que vous communiquerez via cette plateforme de l’IFTH seront traitées de manière confidentielle et elles seront agrégées à celles d’autres participants. Vous disposez d’un droit d’accès à vos données, du droit d’en demander la rectification, l’effacement ou la portabilité, ainsi que du droit de demander la limitation du traitement et de définir des directives sur le sort de vos données après votre mort. Il est toutefois possible que certaines de vos données ne puissent pas être effacées, dans la mesure où leur suppression pourrait rendre impossible ou compromettre gravement la réalisation de la recherche. Dans ce cas, elles seront anonymisées et votre profil sera supprimé. Vous pouvez exercer ces droits auprès de l’investigateur de l’étude (personne qui a sollicité votre participation) ; qui lui-même en informera le/les promoteurs du projet concernés ; ou en contactant directement Gaëlle COURAGE (IFTH) à l’adresse suivante : gcourage@ifth.org.
                                        </p>
                                    </div>
                                    <div class="mb-3">
                                        <label>
                                            <input type="checkbox" v-model="consentement" value="1"> J'accepte les conditions d'utilisation
                                        </label>
                                    </div>
                                    <div class="text-center">
                                        <Loading v-if="loadingRegister" />
                                        <button v-else type="submit" class="button" :disabled="! consentement">
                                            S'inscrire et commencer le formulaire
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
import Loading from '@/components/Loading.vue';

export default {
    data() {
        return {
            loading: true,
            form_token: false,
            loadingRegister: false,
            email: null,
            consentement: 0,
            errors: false,
        }
    },
    created() {
        this.getForm();
    },
    methods: {
        getForm() {
            this.loading = true;
            this.$http
                .get(process.env.VUE_APP_API + '/p/register-forms/' + this.$route.params.token)
                .then(() => {
                    this.form_token = this.$route.params.token;
                    // stop loading
                    this.loading = false;
                }).catch( () => {
                    //this.loading = false;
                    this.$router.push({
                        name: 'notfound'
                    });
                });
        },
        register() {
            this.loadingRegister = true;
            this.errors = false;
            this.$http
                .post(process.env.VUE_APP_API + '/p/register-forms/' + this.form_token, {
                    email: this.email
                })
                .then((response) => {
                    this.loadingRegister = false;
                    // redirect to the form
                    if( response && response.data && response.data.token ) {
                        this.$router.push({
                            name: 'form',
                            params: {
                                token: response.data.token
                            }
                        });
                    }
                }).catch( (err) => {
                    this.errors = err.response.data.message; 
                    this.loadingRegister = false;
                });
        }
    },
    components: {
        Loading
    }
}
</script>
