<template>
    <div class="text-center pt-4 text-white">
        <h1>404</h1>
        <p>
            Oups, la page demandée n'existe pas. Veuillez vérifier le lien qui vous a été partagé.
        </p>
    </div>
</template>

<script>
    export default {
    }
</script>
