<template>
    <div class="container pt-5">
        <div class="row">
            <div class="col-12 text-center pb-3">
                <img class="img-fluid" width="300" src="@/assets/images/logo-ifth.png" alt="IFTH">
            </div>
        </div>
        <div class="row align-items-center justify-content-center">
            <div class="col-lg-10 col-md-10 col-12">
                <div class="card p-3">
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="ifthlogin"></div>
                        </div>
                        <div class="col-sm-6 pt-5 pb-5 text-center">
                            <h1>Merci d'avoir participé à ce sondage.</h1>
                            <p>
                                Pour toute demande de renseignement sur ce test n'hésitez pas à vous référer à l'investigateur de l'étude qui vous a envoyé le lien de participation.
                            </p>
                            <p>
                                Pour rappel :<br>
                                Vous pouvez à tout moment demander la suppression de vos données et vous retirer de cette étude sur demande auprès de l'investigateur de l'étude, ou de Gaëlle COURAGE (IFTH) : <a href="mailto:gcourage@ifth.org" target="_blank">gcourage@ifth.org</a>.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
    }
</script>
