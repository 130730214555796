import Vue from 'vue'
import VueRouter from 'vue-router'
import Form from '../views/Form.vue'
import Complete from '../views/Complete.vue'
import Inscription from '../views/Inscription.vue'
import Page404 from '../views/Page404.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/formulaire/:token',
    name: 'form',
    component: Form
  },
  {
    path: '/formulaire/:token/merci',
    name: 'complete',
    component: Complete
  },
  {
    path: '/inscription-formulaire/:token',
    name: 'inscription',
    component: Inscription
  },
  {
    path: '/*',
    name: 'notfound',
    component: Page404
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
