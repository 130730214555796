import Vue from 'vue';
import App from './App.vue';
import router from './router';
import Axios from 'axios';
import VTooltip from 'v-tooltip';

Vue.use(VTooltip)

Vue.config.productionTip = false

Vue.prototype.$http = Axios;

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
