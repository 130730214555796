<template>
    <div class="container pt-5">
        <div class="row">
            <div class="col-12 text-center pb-3">
                <img class="img-fluid" width="300" src="@/assets/images/logo-ifth.png" alt="IFTH">
            </div>
        </div>
        <div class="row align-items-center justify-content-center" v-if="! start_form">
            <div class="col-lg-10 col-md-10 col-12">
                <div class="card card--ifth p-3 mb-5">
                    <div class="row">
                        <div class="col-sm-6">
                            <div class="ifthlogin"></div>
                        </div>
                        <div class="col-sm-6 pt-5 pb-5">
                            <div v-if="loading" class="text-center">
                                <Loading />
                            </div>
                            <div v-else>
                                <h2 class="text-center">
                                    Plateforme mevaho : méthode de validation humaine On-line
                                </h2>
                                <div class="text-center mb-4"><em>par IFTH</em></div>
                                <div style="font-size:14px;color:#565656">
                                    <p>
                                        Les données à caractère personnel ou non enregistrées à l’occasion de cette étude feront l’objet d’un traitement informatisé automatisé par le promoteur pour son compte ou le compte du projet de recherche concerné.
                                    </p>
                                    <p>
                                        Les données que vous communiquerez via cette plateforme de l’IFTH seront traitées de manière confidentielle et elles seront agrégées à celles d’autres participants. Vous disposez d’un droit d’accès à vos données, du droit d’en demander la rectification, l’effacement ou la portabilité, ainsi que du droit de demander la limitation du traitement et de définir des directives sur le sort de vos données après votre mort. Il est toutefois possible que certaines de vos données ne puissent pas être effacées, dans la mesure où leur suppression pourrait rendre impossible ou compromettre gravement la réalisation de la recherche. Dans ce cas, elles seront anonymisées et votre profil sera supprimé. Vous pouvez exercer ces droits auprès de l’investigateur de l’étude (personne qui a sollicité votre participation) ; qui lui-même en informera le/les promoteurs du projet concernés ; ou en contactant directement Gaëlle COURAGE (IFTH) à l’adresse suivante : gcourage@ifth.org.
                                    </p>
                                </div>
                                <div class="mb-3">
                                    <label>
                                        <input type="checkbox" v-model="consentement" value="1"> J'accepte les conditions d'utilisation
                                    </label>
                                </div>
                                <div class="text-center">
                                    <button @click.prevent="start_form = true" class="button" :disabled="! consentement">
                                        Commencer le formulaire
                                    </button>
                                </div>                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else>
            <div class="row align-items-center justify-content-center">
                <div class="col-lg-10 col-md-12 col-12">
                    <div class="card p-3">
                        <div class="row">
                            <div class="col-12 pl-5 pr-5 pb-3">

                                <div v-if="current_section && current_section.text">
                                    <h2 class="text-center pt-3 pb-1">{{ current_section.text }}</h2>
                                </div>

                                <div class="progress mb-4">
                                    <div class="progress-bar" role="progressbar" :style="'width: ' + progress + '%'" :aria-valuenow="progress" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>

                                <div v-for="(section, index_section) in form.sections" :key="'section_'+index_section">

                                    <div v-if="current_section.id === section.id">
                                        <div>
                                            <div v-for="element in section.elements" :key="'element_'+element.id">
                                                <div v-if="element.type === 'question'" class="d-flex justify-content-between align-items-center mb-4">
                                                    <div style="width:35%" class="pr-3">
                                                        {{ element.question.title }}

                                                        <span v-if="element.question.descriptionfront && element.question.descriptionfront.length > 0" v-tooltip="element.question.descriptionfront">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-info"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="16" x2="12" y2="12"></line><line x1="12" y1="8" x2="12.01" y2="8"></line></svg>
                                                        </span>
                                                    </div>
                                                    <div v-if="element.question.type === 'echelle' || element.question.type === 'custom'" class="d-flex justify-content-around" style="width:65%">
                                                        <div v-for="answer in element.question.answers" :key="'answer_'+answer.id">
                                                            <label class="d-block text-center">
                                                                <div>{{ answer.title }}</div>
                                                                <div>
                                                                    <input type="radio" v-model="element.question.answer_selected" :value="answer.id" @click="saveAnswer(section, element, answer.id)">
                                                                </div>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div v-else-if="element.question.type === 'oui_non'" class="d-flex justify-content-center" style="width:65%">
                                                        <div v-for="answer in element.question.answers" :key="'answer_'+answer.id">
                                                            <label class="ouinon" :class="{active: element.question.answer_selected === answer.id}">
                                                                <div class="mr-2">{{ answer.title }}</div>
                                                                <div>
                                                                    <input type="radio" v-model="element.question.answer_selected" :value="answer.id" @click="saveAnswer(section, element, answer.id)">
                                                                </div>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div v-else-if="element.question.type === 'note'" style="width:65%" class="slidercontainer">
                                                        <vue-slider 
                                                            :lazy="true"
                                                            :tooltip="'none'"
                                                            v-model="element.question.answer_selected"
                                                            :data="element.question.answers" 
                                                            :data-value="'id'"
                                                            :data-label="'title'"
                                                            @change="saveAnswer(section, element, element.question.answer_selected)"
                                                            dotSize="22"
                                                            :dotStyle="{'border-color':'#1867c0'}"
                                                            :railStyle="{height:'10px'}"
                                                            :stepStyle="{display:'none'}"
                                                            :processStyle="{background:'#1867c0'}"
                                                        ></vue-slider>
                                                    </div>
                                                    <div v-else-if="element.question.type === 'text'" style="width:65%">
                                                        <textarea v-model="element.question.answer_selected" class="form-control"></textarea>
                                                    </div>
                                                    <div v-else-if="element.question.type === 'multiple'" class="d-flex justify-content-center" style="width:65%">
                                                        <div v-for="answer in element.question.answers" :key="'answer_'+answer.id">
                                                            <label class="mr-4">
                                                                {{ answer.title }}
                                                                <input type="checkbox" v-model="element.question.multiple_answers" :value="answer.id">
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-else-if="element.type === 'text'" class="pt-3 pb-2">
                                                    <div class="alert alert-primary">
                                                        {{ element.text }}
                                                    </div>
                                                </div>
                                                <div v-else-if="element.type === 'image'" class="mb-4">
                                                    <img :src="element.imageurl" class="img-fluid">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="d-flex justify-content-between">
                                            <div>
                                                <button v-if="form.sections[current_section.index*1-1] && form.sections[current_section.index*1-1].id" class="button button-secondary" @click="displaySection(index_section*1-1)">
                                                    Précédent
                                                </button>
                                            </div>
                                            <div class="text-right">
                                                <Loading class="text-primary" v-if="loadingSave" />
                                                <button class="button" :disabled="! section_complete" @click="saveData(index_section)" v-else>
                                                    Suivant
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import Loading from '@/components/Loading.vue';
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'

export default {
    data() {
        return {
            loading: true,
            user_token: false,
            form: false,
            start_form: false,
            consentement: 0,
            current_section: false,
            section_complete: false,
            progress: 0,
            loadingSave: false,
            error_save: false,
        }
    },
    created() {
        this.getTester();
    },
    methods: {
        getTester() {
            this.loading = true;
            this.$http
                .get(process.env.VUE_APP_API + '/p/forms/' + this.$route.params.token)
                .then(response => {
                    this.user_token = this.$route.params.token;
                    this.form = response.data.form;

                    // if form is complete
                    if( this.form && this.form.infos && this.form.infos.complete ) {
                        // redirect to results
                        this.$router.push({
                            name: 'complete',
                            params: {
                                token: this.user_token,
                            }
                        });
                    }

                    // get current section to display
                    // for each section
                    this.form.sections.forEach( (section, index_section) => {
                        // if no section selected
                        if( ! this.current_section ) {
                            // select current section (will be the first one)
                            this.current_section = {
                                id: section.id,
                                index: index_section,
                                text: section.text
                            };
                        }
                            
                        // for each questions
                        section.elements.forEach( (element) => {
                            // if it's a question
                            if( element.type === 'question' ) {
                                // if question is answered
                                if( element.question && element.question.answered ) {
                                    // select current section
                                    this.current_section = {
                                        id: section.id,
                                        index: index_section,
                                        text: section.text
                                    };
                                }
                            }
                        });
                    });
                    
                    // calculate progress
                    this.calculateProgress();

                    // stop loading
                    this.loading = false;
                }).catch( () => {
                    //this.loading = false;
                    this.$router.push({
                        name: 'notfound'
                    });
                });
        },
        saveAnswer(section, element, answer_id) {
            // mark element has answer_selected (type radio)
            //if( element.question.type === 'echelle' || element.question.type === 'custom' || element.question.type === 'oui_non' ) {
                element.question.answer_selected = answer_id;
                element.question.answered = true;
            //}

            // check other questions
            this.checkSectionAnswers(section);
        },
        checkSectionAnswers(section) {
            let all_answered = true;
            section.elements.forEach( (element) => {
                if( element.type === 'question' ) {
                    if( ! element.question.answered && element.question.type != 'text' && element.question.type != 'multiple' ) {
                        all_answered = false;
                    }
                }
            });
            this.section_complete = all_answered;
        },
        displaySection(index_section) {
            // mark current section as answred
            if( this.form.sections[index_section*1-1] && this.form.sections[index_section*1-1].id ) {
                this.form.sections[index_section*1-1].answered = true;
            }

            // test if section exist
            if( this.form.sections[index_section] && this.form.sections[index_section].id ) {
                // display section
                this.current_section = {
                    id: this.form.sections[index_section].id,
                    index: index_section,
                    text: this.form.sections[index_section].text
                };

                // check questions are answered
                this.checkSectionAnswers(this.form.sections[index_section]);

                // calculate progress
                this.calculateProgress();
            } else {
                // redirect to results
                this.$router.push({
                    name: 'complete',
                    params: {
                        token: this.user_token,
                    }
                });
            }
        },
        calculateProgress() {
            let nb_total = this.form.sections.length;
            /*let nb_complete = 0;
            this.form.sections.forEach( (section) => { 
                if( section.answered ) {
                    nb_complete = nb_complete*1 + 1;
                }
            });
            this.progress = Math.round( (nb_complete/nb_total) * 100 );*/

            let num_current = 0;
            this.form.sections.forEach( (section) => { 
                if( section.id === this.current_section.id ) {
                    num_current = this.current_section.index;
                }
            });
            this.progress = Math.round( (num_current/nb_total) * 100 );
        },
        saveData(index_section) {
            // save answers in database
            // clean data :
            // send this.user_token
            // send questions and answers
            // [
            //    {
            //       formelement_id: xx
            //       answer: xx // if question = text, in backend -> answer save as comment
            //    }
            // ]

            let clean_answers = [];
            this.form.sections[index_section].elements.forEach( (formelement) => {
                if( formelement.type === 'question' && formelement.question ) {
                    clean_answers.push({
                        formelement: formelement.id,
                        answer: formelement.question.answer_selected,
                        multiple: formelement.question.multiple_answers,
                    });
                }
            });

            this.loadingSave = true;
            this.error_save = false;
            this.$http
                .post(process.env.VUE_APP_API + '/p/forms', {
                    tester: this.user_token,
                    answers: clean_answers
                })
                .then( () => {
                    this.loadingSave = false;
                    // go to next section
                    this.displaySection(index_section*1+1);
                }).catch( () => {
                    this.error_save = true;
                    this.loadingSave = false;
                });
            
        }
    },
    components: {
        Loading,
        VueSlider,
    }
}
</script>
